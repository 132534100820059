<template>
  <div>
    <select-shop :loadingButton="loadingButton" v-on:getData="setShop"></select-shop>
    <CCard>
      <CCardBody class="text-center">
        <div v-if="$i18n.locale === 'th'">
          <h2 class="font-weight-bold mt-3">
            แผนบริการของคุณหมดอายุ
          </h2>
          <h5 class="text-dexcription mt-3">
            กรุณาต่ออายุแผนบริการเพื่อใช้งานต่อ
          </h5>
        </div>
        <div v-else>
          <h2 class="font-weight-bold mt-3">
            Your plan has been expired.
          </h2>
          <h5 class="text-dexcription mt-3">
            Please contact our support to continue.
          </h5>
        </div>
        <CButton @click="$router.push('/user')" class="mt-3" color="success" variant="outline">{{ $t('renewPackage') }}
        </CButton><br>

        <CImg src="../../img/exp.jpg" class="img-fluid mt-3" width="50%" />
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      loadingButton: true,
    }
  },
  computed: {
    ...mapGetters(['shops', 'shopObjectId']),
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId
      },
      set(newValue) {
        return this.$store.dispatch('setShop', newValue)
      },
    },
  },
  methods: {
    setShop() {
      this.$store.dispatch('getShop')
      this.$store.dispatch('getPermission')
    },
  },
}
</script>
