var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CHeader',{attrs:{"id":"theHeader","fixed":"","light":""}},[_c('CToggler',{directives:[{name:"c-emit-root-event",rawName:"v-c-emit-root-event:toggle-sidebar-mobile",arg:"toggle-sidebar-mobile"}],staticClass:"d-lg-none",attrs:{"id":"theHeaderToggleSidebarMobile","in-header":""}}),_c('CToggler',{directives:[{name:"c-emit-root-event",rawName:"v-c-emit-root-event:toggle-sidebar",arg:"toggle-sidebar"}],staticClass:"ml-3 d-md-down-none",attrs:{"id":"theHeaderToggleSidebar","in-header":""}}),(_vm.$route.path === '/product' ||
    _vm.$route.path === '/product/category' ||
    _vm.$route.path === '/product/unit' ||
    _vm.$route.path === '/product/category/addProduct' ||
    _vm.$route.name === 'PLUType' ||
    _vm.$route.name === 'ProductDetail' ||
    _vm.$route.name === 'BOMType' ||
    _vm.$route.name === 'SerialType' ||
    _vm.$route.path === '/member/data' ||
    _vm.$route.path === '/shop' ||
    _vm.$route.path === '/user' ||
    _vm.$route.path === '/user/logout' ||
    _vm.$route.name === 'DailyDetail' ||
    _vm.$route.name === 'DailyOrderDetail' ||
    _vm.$route.name === 'VoidBillOrderDetail' ||
    _vm.$route.name === 'StockCardDetail' ||
    _vm.$route.name === 'CreateStockIn' ||
    _vm.$route.name === 'CreateStockOut' ||
    _vm.$route.name === 'SerialInventoryDetail' ||
    _vm.$route.name === 'Poster' ||
    _vm.$route.name === 'TransposeOutDetail' ||
    _vm.$route.name === 'TransposeOut' ||
    _vm.$route.name === 'TransposeIn' ||
    _vm.$route.name === 'TransposeOut' ||
    _vm.$route.name === 'BarcodePrint' ||
    _vm.$route.name === 'ManageDelivery' ||
    _vm.$route.name === 'MangeSalesHours' ||
    _vm.$route.name === 'SettingSalesChannels' ||
    _vm.$route.name === 'GrabIntergration' ||
    _vm.$route.name === 'DeliveryOnOff' ||
    _vm.$route.matched.some(({ path }) => path === '/silom-connect') ||
    _vm.$route.matched.some(({ name }) => name === 'setting')
  )?_c('CHeaderNav',{staticClass:"c-header-nav-items",attrs:{"id":"theHeaderNavItems"}}):_c('CHeaderNav',{staticClass:"c-header-nav-items",attrs:{"id":"theHeaderDatePicker"}},[_c('v-md-date-range-picker',{attrs:{"id":"theHeaderDateRangePicker","opens":"left","locale":_vm.getCurrentLang(),"start-date":_vm.dateSelect,"min-date":_vm.min,"end-date":_vm.dateSelect,"presets":_vm.updatePresetsRangePicker(),"currentLang":_vm.getCurrentLang()},on:{"change":_vm.handleChange}},[_c('template',{staticClass:"activator-wrapper",slot:"input"},[_c('div',{staticClass:"custom-file text-dark",attrs:{"id":"theHeaderDateInput"}},[_c('div',{staticClass:"input-date form-group",attrs:{"role":"group"}},[_c('div',{staticClass:"input-group",staticStyle:{"min-width":"250px"}},[_c('input',{staticClass:"form-control input-group-text",staticStyle:{"-webkit-box-shadow":"unset","box-shadow":"unset","font-weight":"normal","font-size":"inherit","cursor":"inherit","color":"#4f5d73"},attrs:{"id":"theHeaderDateInputField","type":"text","readonly":"readonly"},domProps:{"value":`${_vm.setDate}`}}),_c('div',{staticClass:"input-group-append"},[_c('span',{staticClass:"input-group-icon",staticStyle:{"color":"silver","display":"flex","align-items":"center"},attrs:{"id":"theHeaderDateInputIcon"}},[_c('i',{staticClass:"fi fi-rr-calendar-days",attrs:{"aria-hidden":"true"}})])])])])])])],2)],1),_c('CHeaderNav',{staticClass:"ml-auto p-0",staticStyle:{"display":"flex","align-items":"center"},attrs:{"id":"theHeaderRightNav"}},[(
      _vm.$route.path === '/report/daily' ||
      _vm.$route.path === '/report/transaction' ||
      _vm.$route.path === '/report/salesChannels' ||
      _vm.$route.path === '/report/optional' ||
      _vm.$route.path === '/report/sku' ||
      _vm.$route.path === '/report/sell' ||
      _vm.$route.path === '/report/plu' ||
      _vm.$route.path === '/report/timesheet' ||
      _vm.$route.path === '/report/cashier' ||
      _vm.$route.path === '/report/payment' ||
      _vm.$route.path === '/report/closesell' ||
      _vm.$route.path === '/report/tax' ||
      _vm.$route.path === '/report/taxBillSales' ||
      _vm.$route.path === '/report/transferReport' ||
      _vm.$route.path === '/inventory/transferOut' ||
      _vm.$route.path === '/inventory/transferIn' ||
      _vm.$route.path === '/inventory/adjust-stock' ||
      _vm.$route.path === '/inventory/stock-in' ||
      _vm.$route.path === '/inventory/stock-out' ||
      _vm.$route.path === '/inventory/check-stock' ||
      _vm.$route.path === '/product' ||
      _vm.$route.path === '/product/addProduct' ||
      _vm.$route.name == 'ProductDetail' ||
      _vm.$route.path === '/product/option' ||
      _vm.$route.path === '/product/importProduct' ||
      _vm.$route.path === '/setting/shop' ||
      _vm.$route.path === '/setting/self-service/qr-order' ||
      _vm.$route.path === '/setting/self-service' ||
      _vm.$route.path === '/setting/cashier' ||
      _vm.$route.path === '/user' ||
      _vm.$route.path === '/inventory/transfer/transferReport' ||
      _vm.$route.path === '/setting/payment' ||
      _vm.$route.path === '/setting/permission'
    )?[_c('HelpButton',{directives:[{name:"show",rawName:"v-show",value:(_vm.isWideScreen),expression:"isWideScreen"}],staticClass:"header-item",attrs:{"id":"theHeaderHelpButton","isHeader":true}})]:_vm._e(),_c('TheHeaderAcc',{staticClass:"header-item",attrs:{"id":"theHeaderAccount"}}),_c('TheHeaderDropdownAccnt',{staticClass:"header-item",attrs:{"id":"theHeaderDropdownAccount"}})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }