export default [
  {
    _name: "CSidebarNav",
    _children: [
      {
        _name: "CSidebarNavItem",
        name: "รายงานสรุป",
        to: "/dashboard",
        icon: "cil-chart-line"
      },
      {
        _name: "CSidebarNavTitle",
        _children: ["ทั่วไป"]
      },
      {
        _name: "CSidebarNavDropdown",
        name: "รายงาน",
        route: "/report",
        icon: "cil-bar-chart",
        items: [
          {
            name: "ยอดขายสินค้าประจำวัน",
            to: "/report/daily"
          },
          {
            name: "การขายตามรายการสินค้า",
            to: "/report/sell"
          },
          {
            name: "สรุปจำนวนขายสินค้า",
            to: "/report/sku"
          },
          {
            name: "สรุปจำนวนขายสินค้าตามขนาดบรรจุ",
            to: "/report/plu"
          },
          {
            name: "ยอดขายตามบิล",
            to: "/report/bybill"
          },
          {
            name: "สินค้าที่ไม่มีการขาย",
            to: "/report/unsell"
          },
          {
            name: "ยอดขายแยกตามพนักงาน",
            to: "/report/cashier"
          },
          {
            name: "สรุปยอดขายแยกตามกลุ่มสินค้า",
            to: "/report/category"
          },
          {
            name: "การชำระเงิน",
            to: "/report/payment"
          },
          {
            name: "สรุปภาษีการขาย",
            to: "/report/tax"
          },
          {
            name: "ปิดรอบการขาย",
            to: "/report/closesell"
          },
          {
            name: "ยกเลิกการขาย",
            to: "/report/voidbill"
          },
          {
            name: "รายงานชั่วโมงการทำงาน",
            to: "/report/Timesheet"
          },
          {
            name: "ยอดขายตามตัวเลือก",
            to: "/report/optional"
          },
          {
            name: "ยอดขายตามช่อทางการขาย",
            to: "/report/salesChannels"
          }
        ]
      },
      {
        _name: "CSidebarNavDropdown",
        name: "งานคลังสินค้า",
        route: "/inventory",
        icon: "cil-chart-pie",
        items: [
          {
            name: "สินค้าคงเหลือตาม SKU",
            to: "/inventory/sku"
          },
          {
            name: "สินค้าคงเหลือตามขนาดบรรจุ",
            to: "/inventory/plu"
          },
          {
            name: "ความเคลื่อนไหวสินค้า",
            to: "/inventory/stockcard"
          },
          {
            name: "โอนสินค้าระหว่างสาขา",
            to: "/inventory/transfer",
            badge: {
              color: "success",
              text: "coming soon"
            }
          }
        ]
      },
      {
        _name: "CSidebarNavDivider",
        _class: "m-2"
      },
      {
        _name: "CSidebarNavTitle",
        _children: ["การจัดการ"]
      },
      {
        _name: "CSidebarNavItem",
        name: "สินค้า",
        route: "/product",
        icon: { name: "cil-fastfood", class: "text-white" },
        items: [
          {
            name: "กลุ่มสินค้า",
            to: "/category"
          },
          {
            name: "หมวดสินค้า",
            to: "/unit"
          },
          {
            name: "จัดการสินค้า",
            to: "/"
          },
        ]
      },
      {
        _name: "CSidebarNavItem",
        name: "สมาชิก",
        to: "/member",
        icon: { name: "cil-user-follow", class: "text-white" },
        badge: {
          color: "success",
          text: "coming soon"
        }
      },
      {
        _name: "CSidebarNavItem",
        name: "ร้านและสาขา",
        to: "/shop",
        icon: { name: "cil-basket", class: "text-white" }
      },
      {
        _name: "CSidebarNavItem",
        name: "บัญชีผู้ใช้",
        to: "/user",
        icon: "cil-user"
      }
      /* {
        _name: 'CSidebarNavItem',
        name: 'ออกจากระบบ',
        to: '/logout',
        icon: { name: 'cil-account-logout', class: 'text-white' },
      } */
    ]
  }
];
