<!-- eslint-disable vue/valid-v-for -->
<template>
  <CSidebar fixed :minimize="minimize" :show.sync="show">
    <CSidebarBrand to="/dashboard" id="logoSidebar">
      <img id="img-full-logo" :src="logo" class="c-sidebar-brand-full" width="180" />
      <img id="img-small-logo" :src="favIcon" class="c-sidebar-brand-minimized" width="50" />
    </CSidebarBrand>
    <CSidebarNav v-if="!isLocked">
      <CSidebarNavItem v-if="findMenu('dashboard', -1)" :name="$t('report.dashboard')" to="/dashboard"
        icon="cil-chart-line" id="dashboard">
      </CSidebarNavItem>

      <CSidebarNavItem v-show="checkShowMenu != false" v-if="findMenu('transaction', -1)"
        :name="$t('report.transaction')" to="/report/transaction" icon="cil-history" id="transaction">
      </CSidebarNavItem>
      <CSidebarNavItem v-if="findMenu('currentBill', -1)" :name="$t('report.currentbill')" to="/report/currentbill"
        icon="cil-folder-open" id="currentbill">
      </CSidebarNavItem>
      <CSidebarNavItem v-if="findMenu('grabDashboard', -1)" :name="$t('grabReportMenu')" to="/grabdashboard"
        :class="!permissions.grabDashboard.read ? 'hide-display' : ''" :icon="{
          src: '/img/delivery/grab.png',
          style:
            'width: 22px;height: 22px;margin-left: 8px; margin-right: 25px;border-radius: 20px; box-shadow: rgb(229, 229, 229) 0px 0px 0px 1px;',
          customClasses: 'c-sidebar-nav-icon rounded-circle',
        }" :badge="{ color: 'success', text: 'new', class: 'font-weight-normal' }" data-coreui-toggle="tooltip"
        id="grabReportMenu">
      </CSidebarNavItem>
      <CSidebarNavItem v-if="findMenu('promotionDashboard', -1)" :name="$t('txtSalesPromotionReport')"
        to="/promotiondashboard" :class="!permissions.promotionDashboard.read ? 'hide-display' : ''" :icon="{
          src: '/img/delivery/DISCOUNT.jpg',
          style: 'width: 22px;height: 22px;margin-left: 8px; margin-right: 25px;border-radius: 20px; box-shadow: rgb(229, 229, 229) 0px 0px 0px 1px;',
          customClasses: 'c-sidebar-nav-icon rounded-circle',
        }" :badge="{ color: 'success', text: 'new', class: 'font-weight-normal' }" data-coreui-toggle="tooltip"
        id="txtSalesPromotionReport">

      </CSidebarNavItem>
      <!-- filterReportMenuForGrabAndPromotion -->
      <CSidebarNavTitle v-show="checkShowMenu != false && hasVisibleMenus" v-if="
        findMenu('report', -1) ||
        findMenu('orderDevice', -1) ||
        findMenu('inventory', -1)
      " class="font-weight-normal" id="reportTitle">
        {{ $t("reporttxt") }}
      </CSidebarNavTitle>
      <!-- การขาย -->
      <CSidebarNavDropdown v-if="shouldShowDropdown(salesReportMenus)" :name="$t('salesTxt')" icon="cil-dollar"
        id="salesReportDropdown">
        <CSidebarNavItem v-for="menu in salesReportMenus" :key="menu.label" :name="$t(menu.label)" :to="menu.path"
          :icon="{ name: 'cil-circle', customClasses: 'c-sidebar-nav-icon c-icon iconsize' }"
          :id="'report' + menu.label.charAt(0).toUpperCase() + menu.label.slice(1)" />
      </CSidebarNavDropdown>
      <!-- สินค้าคงคลัง -->
      <CSidebarNavDropdown v-if="shouldShowDropdown(warehouseReportMenus)" :name="$t('inventoryTxt')"
        icon="cil-chart-pie" id="inventoryReportDropdown">
        <CSidebarNavItem v-for="menu in warehouseReportMenus" :key="menu.label" :name="$t(menu.label)" :to="menu.path"
          :icon="{ name: 'cil-circle', customClasses: 'c-sidebar-nav-icon c-icon iconsize' }"
          :id="menu.source + menu.label.charAt(0).toUpperCase() + menu.label.slice(1)" />
      </CSidebarNavDropdown>
      <!-- ภาษี -->
      <CSidebarNavDropdown v-if="shouldShowDropdown(taxReportMenus)" :name="$t('taxTxt')" id="taxReportDropdown"
        icon="cil-money">
        <CSidebarNavItem v-for="menu in taxReportMenus" :key="menu.label" :name="$t(menu.label)" :to="menu.path" :icon="{
          name: 'cil-circle',
          customClasses: 'c-sidebar-nav-icon c-icon iconsize',
        }" data-coreui-toggle="tooltip" :title="$t(menu.label)"
          :id="'report' + menu.label.charAt(0).toUpperCase() + menu.label.slice(1)" />
      </CSidebarNavDropdown>
      <!-- พนักงาน -->
      <CSidebarNavDropdown v-if="shouldShowDropdown(staffReportMenus)" :name="$t('staffTxt')" id="staffReportDropdown"
        icon="cil-people">
        <CSidebarNavItem v-for="menu in staffReportMenus" :key="menu.label" :name="$t(menu.label)" :to="menu.path"
          :icon="{
            name: 'cil-circle',
            customClasses: 'c-sidebar-nav-icon c-icon iconsize',
          }" data-coreui-toggle="tooltip" :title="$t(menu.label)"
          :id="'report' + menu.label.charAt(0).toUpperCase() + menu.label.slice(1)" />
      </CSidebarNavDropdown>
      <!-- เครื่อง order -->
      <CSidebarNavDropdown v-show="checkShowMenu != false" v-if="permissions.orderDevice && findMenu('orderDevice', -1)"
        class="font-weight-normal" :name="$t('orderMachine')" :icon="{
          name: 'cil-clock',
          customClasses: 'c-sidebar-nav-icon c-icon',
        }" id="orderMachineReportDropdown">
        <CSidebarNavItem v-for="(menu, index) in permissions.orderDevice.menu" :key="index" :name="$t(menu.label)"
          :class="!menu.read ? 'hide-display' : ''" :to="menu.path" :icon="{
            name: 'cil-circle',
            customClasses: 'c-sidebar-nav-icon c-icon iconsize',
          }" data-coreui-toggle="tooltip" :title="$t(menu.label)"
          :id="'orderDevice' + menu.label.charAt(0).toUpperCase() + menu.label.slice(1)">
        </CSidebarNavItem>
      </CSidebarNavDropdown>

      <CSidebarNavTitle v-show="checkShowMenu != false" class="font-weight-normal"
        v-if="findMenu('inventory', -1) || findMenu('product', -1)" id="managementTitle">
        {{ $t("management.txtManagement") }}
      </CSidebarNavTitle>

      <CSidebarNavDropdown v-if="shouldShowDropdown(productMenus)" :name="$t('productDetail')" icon="cil-fastfood"
        id="productManagementDropdown">
        <CSidebarNavItem v-for="menu in productMenus" :key="menu.label" :name="$t(menu.label)" :to="menu.path" :icon="{
          name: 'cil-circle',
          customClasses: 'c-sidebar-nav-icon c-icon iconsize',
        }" data-coreui-toggle="tooltip" :title="$t(menu.label)"
          :id="'product' + menu.label.charAt(0).toUpperCase() + menu.label.slice(1)"
          :badge="menu.label === 'salesChannels' || menu.label === 'deliveryManagement' ||  menu.label === 'salesHours' || menu.label === 'manageMenuRanking' ? { color: 'success', text: 'new', class: 'font-weight-normal' } : null"
        />

      </CSidebarNavDropdown>
      <CSidebarNavDropdown v-if="shouldShowDropdown(warehouseMenus)" :name="$t('warehouseTxt')" icon="cil-chart-pie"
        id="inventoryManagementDropdown">
        <CSidebarNavItem v-for="menu in warehouseMenus" :key="menu.label" :name="$t(menu.label)" :to="menu.path" :icon="{
          name: 'cil-circle',
          customClasses: 'c-sidebar-nav-icon c-icon iconsize',
        }" data-coreui-toggle="tooltip" :title="$t(menu.label)"
          :id="menu.source + menu.label.charAt(0).toUpperCase() + menu.label.slice(1)" />
      </CSidebarNavDropdown>
      <CSidebarNavDropdown v-if="shouldShowDropdown(transferDropdownMenus)" :name="$t('invenTransfer')"
        fontIcon="fi fi-rr-shipping-fast" id="inventoryTransferDropdown">
        <CSidebarNavItem v-for="menu in transferDropdownMenus" :key="menu.label" :name="$t(menu.label)" :to="menu.path"
          :icon="{
            name: 'cil-circle',
            customClasses: 'c-sidebar-nav-icon c-icon iconsize',
          }" data-coreui-toggle="tooltip" :title="$t(menu.label)"
          :badge="menu.label === 'transferInMenu' || menu.label === 'transferOutMenu' ? { color: 'info', text: 'beta', class: 'font-weight-normal' } : null"
          :id="'inventory' + menu.label.charAt(0).toUpperCase() + menu.label.slice(1)" />
      </CSidebarNavDropdown>
      <CSidebarNavDropdown v-if="connectEcommerce" :name="$t('EcommerceMenu')" fontIcon="fi fi-rr-link"
        id="ecommerceDropDown">
        <CSidebarNavItem :name="$t('EcommerceMenuTag')" to="/ecommerce/ecommerceSetting" :icon="{
          name: 'cil-circle',
          customClasses: 'c-sidebar-nav-icon c-icon iconsize',
        }" :badge="{ color: 'success', text: 'new', class: 'font-weight-normal' }" id="ecommerceSetting">
        </CSidebarNavItem>
        <CSidebarNavItem :name="$t('ProductListMenu')" to="/ecommerce/ProductList" :icon="{
          name: 'cil-circle',
          customClasses: 'c-sidebar-nav-icon c-icon iconsize',
        }" :badge="{ color: 'success', text: 'new', class: 'font-weight-normal' }" id="ecommerceProductlist">
        </CSidebarNavItem>
        <CSidebarNavItem :name="$t('OrderListMenu')" to="/ecommerce/OrderList" :icon="{
          name: 'cil-circle',
          customClasses: 'c-sidebar-nav-icon c-icon iconsize',
        }" :badge="{ color: 'success', text: 'new', class: 'font-weight-normal' }" id="ecommerceOrderlist">
        </CSidebarNavItem>

        <template v-for="(menu, index) in permissions.product.menu">
          <template v-if="
            menu.label !== 'importProductTxt' &&
            menu.label !== 'txtOptionCategory' &&
            menu.label !== 'optional' &&
            menu.label !== 'product'
          ">
            <CSidebarNavItem :key="index" :name="$t(menu.label)" :class="!menu.read ? 'hide-display' : ''"
              :to="menu.path" :icon="{
                name: 'cil-circle',
                customClasses: 'c-sidebar-nav-icon c-icon iconsize',
              }" data-coreui-toggle="tooltip" :title="$t(menu.label)"
              :id="'product' + menu.label.charAt(0).toUpperCase() + menu.label.slice(1)">
            </CSidebarNavItem>
          </template>
        </template>
        <CSidebarNavItem :name="$t('salesChannels')" to="/product/saleschannels"
          :badge="{ color: 'success', text: 'new', class: 'font-weight-normal' }" :icon="{
            name: 'cil-circle',
            customClasses: 'c-sidebar-nav-icon c-icon iconsize',
          }" id="productSalesChannels">
        </CSidebarNavItem>
      </CSidebarNavDropdown>
      <CSidebarNavItem :name="$t('management.shopBranch')" to="/shop" icon="cil-basket" id="shop">
      </CSidebarNavItem>
      <CSidebarNavItem v-if="isGrabOrderEnabled" :name="$t('silomPOSPromotion')" to="/promotion" icon="cil-tag"
        :badge="{ color: 'success', text: 'new', class: 'font-weight-normal' }" id="promotion">
      </CSidebarNavItem>

      <CSidebarNavTitle class="font-weight-normal" id="selfServiceTitle">
        {{ $t("selfService") }}
      </CSidebarNavTitle>
      <CSidebarNavItem :name="$t('smartSettingTitle')" to="/setting/self-service" icon="cil-restaurant"
        id="smartSettingTitle">
      </CSidebarNavItem>
      <CSidebarNavItem v-for="(menu, index) in filterSelfService()" :key="index" :name="getSidebarName(menu.label)"
        :class="!menu.read ? 'hide-display' : ''" :to="menu.path" :fontIcon="getSidebarIcon(menu.label)"
        data-coreui-toggle="tooltip" :title="$t(menu.label)"
        :badge="{ color: 'success', text: 'Smart+', class: 'font-weight-normal' }"
        :id="'setting' + menu.label.charAt(0).toUpperCase() + menu.label.slice(1)">
      </CSidebarNavItem>
      <CSidebarNavTitle v-show="checkShowMenu != false" v-if="findMenu('member', -1)" class="font-weight-normal"
        id="memberTitle">
        {{ $t("member.txtmember") }}
      </CSidebarNavTitle>
      <CSidebarNavDropdown v-show="checkShowMenu != false" v-if="findMenu('member', -1)" :name="$t('member.txtmember')"
        icon="cil-user-follow" id="memberDropdown">
        <CSidebarNavItem v-show="checkShowMenu != false" v-if="findMenu('member', -1)" :name="$t('member.txtmember')"
          to="/member/data" :icon="{
            name: 'cil-circle',
            customClasses: 'c-sidebar-nav-icon c-icon iconsize',
          }" data-coreui-toggle="tooltip" id="memberMenu">
        </CSidebarNavItem>
        <template v-for="(menu, index) in filterReportMenuForMember()">
          <CSidebarNavItem :name="$t(menu.label)" :class="!menu.read ? 'hide-display' : ''" :to="menu.path" :icon="{
            name: 'cil-circle',
            customClasses: 'c-sidebar-nav-icon c-icon iconsize',
          }" :id="'member' + menu.label.charAt(0).toUpperCase() + menu.label.slice(1)">
          </CSidebarNavItem>
        </template>

        <CSidebarNavItem
          v-show="checkShowMenu != false"
          v-if="findMenu('member', -1)"
          :name="$t('importMember')"
          to="/member/ImportMember"
          :icon="{
            name: 'cil-circle',
            customClasses: 'c-sidebar-nav-icon c-icon iconsize',
          }"
          data-coreui-toggle="tooltip"
          id="importMember"
          :badge="{ color: 'success', text: 'new', class: 'font-weight-normal' }"
        >
        </CSidebarNavItem>
      </CSidebarNavDropdown>

      <CSidebarNavItem v-show="checkShowMenu != false" v-if="permissions.member && findMenu('member', -1)"
        icon="cil-sitemap" :href="crmwebportal" target="_blank" :name="$t('member.crm')" :badge="{
          color: 'muted',
          text: '',
          style: 'font-size: 14px;',
          class: 'fas fa-external-link-alt',
        }" id="memberCRM">
      </CSidebarNavItem>

      <CSidebarNavTitle id="settingTitle" v-show="checkShowMenu != false" class="font-weight-normal">
        {{ $t("setting") }}
      </CSidebarNavTitle>
      <CSidebarNavDropdown v-if="shouldShowDropdown(settingDropdownMenus)" :name="$t('setting')" id="settingDropdown"
        icon="cil-settings">
        <CSidebarNavItem v-for="menu in settingDropdownMenus" :key="menu.label" :name="$t(menu.label)" :to="menu.path"
          :icon="{
            name: 'cil-circle',
            customClasses: 'c-sidebar-nav-icon c-icon iconsize',
          }" data-coreui-toggle="tooltip" :title="$t(menu.label)"
          :id="'setting' + menu.label.charAt(0).toUpperCase() + menu.label.slice(1)" 
           :badge="menu.label === 'silom-alert' ? { color: 'success', text: 'new', class: 'font-weight-normal' } : null"
          />

      </CSidebarNavDropdown>
      <CSidebarNavDropdown v-if="shouldShowDropdown(settingShopDropdownMenus)" :name="$t('shopsetting')"
        fontIcon="fi fi-rr-shop" id="shopSettingDropdown">
        <CSidebarNavItem :name="$t('management.user')" to="/user" :icon="{
          name: 'cil-circle',
          customClasses: 'c-sidebar-nav-icon c-icon iconsize',
        }" data-coreui-toggle="tooltip" id="settingUser">
        </CSidebarNavItem>
        <CSidebarNavItem v-for="menu in settingShopDropdownMenus" :key="menu.label" :name="$t(menu.label)"
          :to="menu.path" :icon="{
            name: 'cil-circle',
            customClasses: 'c-sidebar-nav-icon c-icon iconsize',
          }" data-coreui-toggle="tooltip"
          :badge="menu.label === 'grabIntegration' || menu.label === 'deliveryShopSetting' ? { color: 'success', text: 'new', class: 'font-weight-normal' } : null"
          :id="'setting' + menu.label.charAt(0).toUpperCase() + menu.label.slice(1)" :title="$t(menu.label)" />
        <CSidebarNavItem v-if="shops.length > 0" name="Silom Smart Connect" to="/silom-connect" :icon="{
          name: 'cil-circle',
          customClasses: 'c-sidebar-nav-icon c-icon iconsize',
        }" id="settingSilomLineAlert">
        </CSidebarNavItem>
        <!-- v-if="!isGrabOrderEnabled"  -->
      </CSidebarNavDropdown>

      <CSidebarNavItem style="margin-top: 15px; margin-bottom: 15px;">
        <i class="fi fi-rr-moon" style="margin-left: 28px; margin-right: 28px;"></i>

        <span class="text-nightMode">{{ $t("nightMode") }}</span>
        <label class="switch ml-5">
          <input type="checkbox" v-model="isDarkMode" @change="toggleTheme" />
          <span class="slider round"></span>
        </label>
      </CSidebarNavItem>
    </CSidebarNav>
    <CSidebarNav v-if="isLocked">
      <CSidebarNavItem :name="$t('management.shopBranch')" to="/shop" icon="cil-basket" id="shop">
      </CSidebarNavItem>
      <CSidebarNavItem :name="$t('management.user')" to="/user" icon="cil-restaurant" data-coreui-toggle="tooltip"
        id="settingUser">
      </CSidebarNavItem>
      <CSidebarNavItem style="margin-top: 15px; margin-bottom: 15px;">
        <i class="fi fi-rr-moon" style="margin-left: 28px; margin-right: 28px;"></i>

        <span class="text-nightMode">{{ $t("nightMode") }}</span>
        <label class="switch ml-5">
          <input type="checkbox" v-model="isDarkMode" @change="toggleTheme" />
          <span class="slider round"></span>
        </label>
      </CSidebarNavItem>
    </CSidebarNav>
    <CSidebarMinimizer id="minimizer" class="d-md-down-none" @click.native="minimize = !minimize" />
  </CSidebar>
</template>

<script>
import nav from "./_nav";
import { mapGetters } from "vuex";
import permis from "../util/permission";
import { CSidebarNavDropdown, CSidebarNavItem } from "@coreui/vue";
import pos from "@/services/local";

export default {
  name: "TheSidebar",
  data() {
    return {
      minimize: false,
      nav,
      show: "responsive",
      logo: "/SilomPOS_logo.png",
      favIcon: "/SilomPos_logo_Noname.png",
      shop: null,
      bussiness: null,
      isDarkMode: null,
      lightMode: require("!!raw-loader!../assets/styles/sidebar-light.css").default,
      darkMode: require("!!raw-loader!../assets/styles/sidebar-dark.css").default,
      currentPlan: "",
      orderShopSetting: {},
    };
  },
  created() {
    this.shop = this.shops.find((i) => this.shopObjectId === i.objectId);
    if (this.shop) {
      this.currentPlan = this.shop.currentPlan;
    } else {
      this.currentPlan = ""; // กำหนดค่า default
    }
    this.isDarkMode = JSON.parse(localStorage.getItem("darkMode"));
    this.setTheme(this.isDarkMode);
    if (localStorage.getItem("token") == null) {
      this.$router.push("/login?redirect=" + window.location.pathname);
    } else {
      this.$store.dispatch("getUser");
    }
    this.browserName = this.detectBrowser();
    this.getPromotionSidebar();
  },
  mounted() {
    this.shop = this.shops.find((i) => this.shopObjectId === i.objectId);
    if (this.shop) {
      this.currentPlan = this.shop.currentPlan;
    }
  },
  computed: {
    ...mapGetters(["users", "shops", "shopsetting", "permissions", "dataPermissions", "isLocked"]),
    crmwebportal() {
      const token = `${localStorage.getItem("token")}`;
      const url = process.env.VUE_APP_CRM_WEBPORTAL + "?token=" + token;
      return url;
    },
    loginwith() {
      return localStorage.getItem("loginwith");
    },
    datashop: {
      get() {
        return this.$store.getters.shopsetting;
      },
      set(newValue) {
        return this.$store.dispatch("setShopSetting", newValue);
      },
    },
    hasVisibleMenus() {
      return (
        this.shouldShowDropdown(this.salesReportMenus) ||
        this.shouldShowDropdown(this.warehouseReportMenus) ||
        this.shouldShowDropdown(this.taxReportMenus) ||
        this.shouldShowDropdown(this.staffReportMenus) ||
        this.hasOrderDeviceMenus
      );
    },
    hasOrderDeviceMenus() {
      return (
        this.permissions.orderDevice &&
        this.permissions.orderDevice.menu.some((menu) => menu.read)
      );
    },
    salesReportMenus() {
      const allowedLabels = [
        "salesReportByDate",
        "salesReportByBill",
        "salesQuantitySummaryByProductReport",
        "quantitySummaryByPLUReport",
        "optionalReport",
        "salesChannelsReport",
        "salesSummaryByCategoryReport",
        "paymentReport",
        "drawerReport",
        "voidBillReport",
        "cashInOutReport",
        'nonSellingProduct',
        "tableAmount",
        "optionSalesByCostMenu"
      ];

      const inventoryMenus = this.permissions.inventory?.menu || [];
      const transferReportMenus = this.permissions.report?.menu || [];

      return this.getFilteredMenus([inventoryMenus, transferReportMenus], allowedLabels);
    },
    warehouseReportMenus() {
      const allowedLabels = [
        "stockInByListReport",
        "stockOutByListReport",
        "nonAdjustStockProductReport",
        "exportProductReport",
        "inventoryReport",
        "inventoryReportByPLU",
        "inventoryReportBySerial",
        "inventoryReportByLessStock",
        "inventoryReportByOutOfStock",
        'optionSalesByDateMenu'
      ];

      const inventoryMenus = this.permissions.inventory?.menu.map((menu) => ({
        ...menu,
        source: "inventory",
      })) || [];
      const transferReportMenus = this.permissions.report?.menu.map((menu) => ({
        ...menu,
        source: "report",
      })) || [];

      return this.getFilteredMenus([inventoryMenus, transferReportMenus], allowedLabels);
    },
    taxReportMenus() {
      const allowedLabels = [
        "salesTaxReport",
        "newPaymentReport",
        "taxBillSalesMenu"
      ];
      const inventoryMenus = this.permissions.inventory?.menu || [];
      const reportMenus = this.permissions.report?.menu || [];

      return this.getFilteredMenus([inventoryMenus, reportMenus], allowedLabels);
    },
    staffReportMenus() {
      const allowedLabels = [
        "timesheetReport",
        "salesSummaryByCashierReport"
      ];
      const inventoryMenus = this.permissions.inventory?.menu || [];
      const reportMenus = this.permissions.report?.menu || [];

      return this.getFilteredMenus([inventoryMenus, reportMenus], allowedLabels);
    },
    productMenus() {
      const allowedLabels = [
        "product",
        "category",
        "unit",
        "optional",
        "txtOptionCategory",
        "deliveryManagement",
        "salesChannels",
        "salesHours",
        "manageMenuRanking"
      ];

      const productMenus = this.permissions.product?.menu || [];

      return this.getFilteredMenus([productMenus], allowedLabels);
    },
    warehouseMenus() {
      const allowedLabels = [
        "stockInDocument",
        "stockOutDocument",
        "checkStockDoc",
        "adjustStockDocument",
        "stockMovement",
        "importProductTxt",
      ];

      const inventoryMenus = this.permissions.inventory?.menu.map((menu) => ({
        ...menu,
        source: "inventory",
      })) || [];
      const productMenus = this.permissions.product?.menu.map((menu) => ({
        ...menu,
        source: "product",
      })) || [];

      return this.getFilteredMenus([inventoryMenus, productMenus], allowedLabels);
    },
    transferDropdownMenus() {
      const allowedLabels = [
        "transferOutMenu",
        "transferInMenu",
        "transferReportMenu",
      ];

      const inventoryMenus = this.permissions.inventory?.menu || [];
      const transferReportMenus = this.permissions.report?.menu || [];

      return this.getFilteredMenus([inventoryMenus, transferReportMenus], allowedLabels);
    },
    settingDropdownMenus() {
      const allowedLabels = [
        "language",
        "timezone",
        "payment",
        "cashier",
        "permission",
        "deliveryProviders",
        "silom-alert",
      ];

      const settingMenus = this.permissions.setting?.menu || [];

      return this.getFilteredMenus([settingMenus], allowedLabels);
    },
    settingShopDropdownMenus() {
      const allowedLabels = [
        "shop",
        "administratorTools",
        "menuSetting",
        'grabIntegration',
        'deliveryShopSetting',
      ];

      const settingMenus = this.permissions.setting?.menu || [];

      return this.getFilteredMenus([settingMenus], allowedLabels);
    },
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId;
      },
      set(newValue) {
        return this.$store.dispatch("setShop", newValue);
      },
    },
    businessType() {
      this.shop = this.shops.find((i) => this.shopObjectId === i.objectId);
      if (this.shop) {
        this.business = this.shop.businessType;
      }
      return this.business;
    },
    checkShowMenu() {
      if (this.dataPermissions.lock === false) {
        return true;
      } else {
        return false;
      }
    },
    browserName() {
      let userAgent = navigator.userAgent;
      let browserName = "";
      if (userAgent.match(/chrome|chromium|crios/i)) {
        browserName = "chrome";
      } else if (userAgent.match(/firefox|fxios/i)) {
        browserName = "firefox";
      } else if (userAgent.match(/safari/i)) {
        browserName = "safari";
      } else if (userAgent.match(/opr/i)) {
        browserName = "opera";
      } else if (userAgent.match(/edg/i)) {
        browserName = "edge";
      } else {
        browserName = "No browser detection";
      }
      return browserName;
    },
    connectEcommerce() {
      if (
        this.shop &&
        this.shop.connectEcommerce !== null &&
        this.shop.connectEcommerce === true
      ) {
        return true;
      } else {
        return false;
      }
    },
    isGrabOrderEnabled() {
      return this.orderShopSetting?.isGrabOrderEnabled || false;
    },
  },
  watch: {
    shopObjectId(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getPromotionSidebar();
      }
    },
  },
  mounted() {
    const storedDarkMode = JSON.parse(localStorage.getItem("darkMode"));
    if (storedDarkMode !== null)
      // กำหนดค่า isDarkMode ตามที่เก็บไว้ใน localStorage

      this.$store.dispatch("getUser");
    this.$store.dispatch("getShop");
    this.$store.dispatch("getShopSetting");
    this.$store.dispatch("getPermission");
    this.$root.$on("toggle-sidebar", () => {
      const sidebarOpened = this.show === true || this.show === "responsive";
      this.show = sidebarOpened ? false : "responsive";
    });
    this.$root.$on("toggle-sidebar-mobile", () => {
      const sidebarClosed = this.show === "responsive" || this.show === false;
      this.show = sidebarClosed ? true : "responsive";
    });
  },
  methods: {
    getFilteredMenus(menuGroups, allowedLabels) {
      return menuGroups
        .flat()
        .filter((menu) => allowedLabels.includes(menu.label) && menu.read === true);
    },
    shouldShowDropdown(filteredMenus) {
      return filteredMenus.length > 0;
    },
    detectBrowser() {
      let userAgent = navigator.userAgent;
      let browserName = "";
      if (userAgent.match(/chrome|chromium|crios/i)) {
        browserName = "chrome";
      } else if (userAgent.match(/firefox|fxios/i)) {
        browserName = "firefox";
      } else if (userAgent.match(/safari/i)) {
        browserName = "safari";
      } else if (userAgent.match(/opr/i)) {
        browserName = "opera";
      } else if (userAgent.match(/edg/i)) {
        browserName = "edge";
      } else {
        browserName = "No browser detection";
      }
      return browserName;
    },
    toggleTheme() {
      this.isDarkMode = JSON.parse(localStorage.getItem("darkMode"));

      this.isDarkMode = !this.isDarkMode;
      localStorage.setItem("darkMode", JSON.stringify(this.isDarkMode));

      this.setTheme(this.isDarkMode);
      this.$forceUpdate();
    },
    setTheme(isDarkMode) {
      if (isDarkMode) {
        this.loadCSS("sidebar-dark.css");
        this.unloadCSS("sidebar-light.css");
      } else {
        this.loadCSS("sidebar-light.css");
        this.unloadCSS("sidebar-dark.css");
      }
    },
    loadCSS(fileName) {
      const style = document.createElement("style");
      style.type = "text/css";
      // style.styleSheet = fileName;
      style.id = fileName;

      if (this.isDarkMode) {
        style.textContent = this.darkMode;
      } else {
        style.textContent = this.lightMode;
      }
      document.head.appendChild(style);
    },
    unloadCSS(fileName) {
      const link = document.getElementById(fileName);
      if (link) {
        document.head.removeChild(link);
      }
    },
    findMenu(keyname, keyindexMenu) {
      return permis.showMenu(keyname, keyindexMenu);
    },
    filterReportMenuForGrabAndPromotion() {
      const allowedLabels = ["promotionReportMenu", "grabReportMenu"];
      return this.permissions.report.menu.filter((menu) =>
        allowedLabels.includes(menu.label)
      );
    },
    filterReportMenuForMember() {
      const allowedLabels = ["phoneReportMenu"];
      return this.permissions.report.menu.filter((menu) =>
        allowedLabels.includes(menu.label)
      );
    },
    filterSelfService() {
      if (!this.permissions.setting) return [];
      const allowedLabels = ["kiosk", "qrOrder"];
      return this.permissions.setting.menu.filter((menu) =>
        allowedLabels.includes(menu.label)
      );
    },
    getSidebarName(key) {
      switch (key) {
        case "qrOrder":
          return this.$i18n.t("setUpQR");
        case "kiosk":
          return this.$i18n.t("setUpKiosk");
        default:
          break;
      }
    },
    getSidebarIcon(key) {
      switch (key) {
        case "qrOrder":
          return "fi fi-rr-mobile-notch";
        case "kiosk":
          return "fi fi-rr-tablet-android-alt";
        default:
          break;
      }
    },
    getPromotionSidebar() {
      const uid = localStorage.getItem("uid");
      const shopObjectId = localStorage.getItem("shopObjectId");
      let params = {
        shopObjectId: shopObjectId,
      };
      pos({
        method: "get",
        url: "/api/v2.0/" + uid + "/OrderShopSetting",
        params: params,
        headers: { shopObjectId: shopObjectId },
      }).then((res) => {
        console.log(res);
        
        this.orderShopSetting = res?.data?.data[0] || null;
      });
    },
  },
};
</script>

<style>
html:not([dir="rtl"]) .c-sidebar-nav-dropdown-items .c-sidebar-nav-link .c-sidebar-nav-icon,
html:not([dir="rtl"]) .c-sidebar-nav-dropdown-items .c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon {
  margin-left: -30px;
  height: 10px;
}

.c-sidebar-minimized .c-sidebar-brand-minimized {
  width: 60%;
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  /* Adjusted width */
  height: 20px;
  /* Adjusted height */
  margin-left: 30px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  /* Adjusted size */
  width: 14px;
  /* Adjusted size */
  left: 3px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked+.slider {
  background-color: #29b289;
}

input:focus+.slider {

  box-shadow: 0 0 1px #29b289;
}

input:checked+.slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

.slider.round {
  border-radius: 20px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
