<template>
  <div class="c-app">
    <TheSidebar />
    <div class="c-wrapper">
      <TheHeader />
      <div class="c-body" style="background-color: #f6f6f6;">
        <main class="c-main" style="padding-top: 1.25rem;">
          <CContainer fluid class="container-panding">
            <transition name="fade" v-if="isLockedChecker === false">
              <router-view></router-view>
            </transition>
            <div v-else>
              <standard-plan></standard-plan>
              <!-- <div v-if="lockDescription === 'standard' ">
                <standard-plan></standard-plan>
              </div>
              <div v-else-if="lockDescription === 'expired'">
                <expire></expire>
              </div> -->
            </div>
          </CContainer>
        </main>
      </div>
      <TheFooter />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TheSidebar from './TheSidebar'
import TheHeader from './TheHeader'
import TheFooter from './TheFooter'
import StandardPlan from './StandardPlan'
import Expire from './Expire'
import access from '@/util/access'

export default {
  name: 'TheContainer',
  components: {
    TheSidebar,
    TheHeader,
    TheFooter,
    StandardPlan,
    Expire,
  },
  computed: {
    ...mapGetters([
      'shops',
      'shopObjectId',
      'users',
      'isLocked',
      'lockDescription',
    ]),
    isLockedChecker() {
      if (this.isLocked === false) {
        return false
      } else {
        if (this.$route.path === '/user' || this.$route.path === '/shop' || this.$route.path === '/user/package/renew') {
          return false
        } else {
          return true
        }
      }
    },
  },
  mounted() {
    window.addEventListener('unload', access.getRefresh())
  },
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.container-panding {
  padding-right: 1.25rem;
  padding-left: 1.25rem
}

@media only screen and (max-width: 992px) {
  .container-panding {
    padding-right: 7.5px;
    padding-left: 7.5px;
  }
}
</style>
