<template>
  <CHeader id="theHeader" fixed light class="">
    <CToggler id="theHeaderToggleSidebarMobile" in-header class="d-lg-none" v-c-emit-root-event:toggle-sidebar-mobile />
    <CToggler id="theHeaderToggleSidebar" in-header class="ml-3 d-md-down-none" v-c-emit-root-event:toggle-sidebar />
    <CHeaderNav id="theHeaderNavItems" class="c-header-nav-items " v-if="$route.path === '/product' ||
      $route.path === '/product/category' ||
      $route.path === '/product/unit' ||
      $route.path === '/product/category/addProduct' ||
      $route.name === 'PLUType' ||
      $route.name === 'ProductDetail' ||
      $route.name === 'BOMType' ||
      $route.name === 'SerialType' ||
      $route.path === '/member/data' ||
      $route.path === '/shop' ||
      $route.path === '/user' ||
      $route.path === '/user/logout' ||
      $route.name === 'DailyDetail' ||
      $route.name === 'DailyOrderDetail' ||
      $route.name === 'VoidBillOrderDetail' ||
      $route.name === 'StockCardDetail' ||
      $route.name === 'CreateStockIn' ||
      $route.name === 'CreateStockOut' ||
      $route.name === 'SerialInventoryDetail' ||
      $route.name === 'Poster' ||
      $route.name === 'TransposeOutDetail' ||
      $route.name === 'TransposeOut' ||
      $route.name === 'TransposeIn' ||
      $route.name === 'TransposeOut' ||
      $route.name === 'BarcodePrint' ||
      $route.name === 'ManageDelivery' ||
      $route.name === 'MangeSalesHours' ||
      $route.name === 'SettingSalesChannels' ||
      $route.name === 'GrabIntergration' ||
      $route.name === 'DeliveryOnOff' ||
      $route.matched.some(({ path }) => path === '/silom-connect') ||
      $route.matched.some(({ name }) => name === 'setting')
    ">
    </CHeaderNav>
    <CHeaderNav id="theHeaderDatePicker" class=" c-header-nav-items" v-else>
      <v-md-date-range-picker id="theHeaderDateRangePicker" opens="left" :locale="getCurrentLang()"
        :start-date="dateSelect" :min-date="min" :end-date="dateSelect" :presets="updatePresetsRangePicker()"
        :currentLang="getCurrentLang()" @change="handleChange">
        <template slot="input" class="activator-wrapper">
          <div id="theHeaderDateInput" class=" custom-file text-dark">
            <div role="group" class="input-date form-group">
              <div class="input-group" style="min-width: 250px;">
                <input id="theHeaderDateInputField" type="text" readonly="readonly"
                  class="form-control input-group-text" :value="`${setDate}`"
                  style="-webkit-box-shadow: unset; box-shadow: unset; font-weight: normal; font-size: inherit;cursor: inherit;  box-shadow: unset; color: #4f5d73;" />
                <div class="input-group-append">
                  <span id="theHeaderDateInputIcon" class="input-group-icon"
                    style="color: silver; display: flex; align-items: center;">
                    <i class="fi fi-rr-calendar-days" aria-hidden="true"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </template>
      </v-md-date-range-picker>
    </CHeaderNav>
    <CHeaderNav id="theHeaderRightNav" class="ml-auto p-0" style="display: flex; align-items: center;">
      <!-- <label @click="$router.push('/setting/sidebarHelp')">
      <i class="fa fa-question-circle" style="margin: 10px 10px 0px 0px; font-size: 24px; color: silver;"></i>
    </label> -->
      <template v-if="
        $route.path === '/report/daily' ||
        $route.path === '/report/transaction' ||
        $route.path === '/report/salesChannels' ||
        $route.path === '/report/optional' ||
        $route.path === '/report/sku' ||
        $route.path === '/report/sell' ||
        $route.path === '/report/plu' ||
        $route.path === '/report/timesheet' ||
        $route.path === '/report/cashier' ||
        $route.path === '/report/payment' ||
        $route.path === '/report/closesell' ||
        $route.path === '/report/tax' ||
        $route.path === '/report/taxBillSales' ||
        $route.path === '/report/transferReport' ||
        $route.path === '/inventory/transferOut' ||
        $route.path === '/inventory/transferIn' ||
        $route.path === '/inventory/adjust-stock' ||
        $route.path === '/inventory/stock-in' ||
        $route.path === '/inventory/stock-out' ||
        $route.path === '/inventory/check-stock' ||
        $route.path === '/product' ||
        $route.path === '/product/addProduct' ||
        $route.name == 'ProductDetail' ||
        $route.path === '/product/option' ||
        $route.path === '/product/importProduct' ||
        $route.path === '/setting/shop' ||
        $route.path === '/setting/self-service/qr-order' ||
        $route.path === '/setting/self-service' ||
        $route.path === '/setting/cashier' ||
        $route.path === '/user' ||
        $route.path === '/inventory/transfer/transferReport' ||
        $route.path === '/setting/payment' ||
        $route.path === '/setting/permission'
      ">
        <HelpButton id="theHeaderHelpButton" v-show="isWideScreen" :isHeader="true" class="header-item"></HelpButton>
      </template>
      <TheHeaderAcc id="theHeaderAccount" class="header-item" />
      <TheHeaderDropdownAccnt id="theHeaderDropdownAccount" class="header-item" />
    </CHeaderNav>
  </CHeader>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import TheHeaderAcc from './TheHeaderAcc'
import i18n from '@/plugins/i18n'
import { CHeaderNav } from '@coreui/vue';
import HelpButton from './HelpButton.vue';
import TheHeaderDropdownAccnt from './TheHeaderDropdownAccnt.vue';

export default {
  name: 'TheHeader',
  components: {
    TheHeaderAcc,
    HelpButton,
    TheHeaderDropdownAccnt
  },
  data() {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    const minDate = new Date(today)
    minDate.setMonth(minDate.getMonth() - 3)
    return {
      presets: ['Today'],
      shopObjectId: {},
      values: [],
      dateSelect: moment(new Date())
        .locale('en')
        .format('LLLL'),
      min: minDate,
      dateText: '',
      locale: i18n.locale,
      presetsRangePicker: [{
        label: i18n.t('today'),
        range: this.getRange(0, 0)
      }, {
        label: i18n.t('yesterday'),
        range: this.getRange(1, 1)
      }, {
        label: i18n.t('last7days'),
        range: this.getRange(6, 0)
      }, {
        label: i18n.t('last30days'),
        range: this.getRange(29, 0)
      }, {
        label: i18n.t('thismonth'),
        range: this.getRange(0, 0, 'month')
      }, {
        label: i18n.t('lastmonth'),
        range: this.getRange(1, 1, 'month')
      }],
      windowWidth: window.innerWidth,
    }
  },
  created() {
    this.locale = i18n.locale
  },
  computed: {
    ...mapGetters(['shops', 'date', 'permissions']),
    setDate() {
      let start = moment(this.date.start).format('DD/MM/YYYY')
      let end = moment(this.date.end).format('DD/MM/YYYY')

      return start + ' - ' + end
    },
    isWideScreen() {
      return this.windowWidth > 450;
    },
  },
  mounted() {
    window.addEventListener("resize", this.updateWindowWidth); // ฟังการเปลี่ยนแปลงขนาดหน้าจอ
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateWindowWidth); // ลบ event listener เมื่อ component ถูกทำลาย
  },
  methods: {
    updateWindowWidth() {
      this.windowWidth = window.innerWidth; // อัปเดตค่าความกว้างของหน้าจอ
    },
    updatePresetsRangePicker() {
      return this.presetsRangePicker = [{
        label: i18n.t('today'),
        range: this.getRange(0, 0)
      }, {
        label: i18n.t('yesterday'),
        range: this.getRange(1, 1)
      }, {
        label: i18n.t('last7days'),
        range: this.getRange(6, 0)
      }, {
        label: i18n.t('last30days'),
        range: this.getRange(29, 0)
      }, {
        label: i18n.t('thismonth'),
        range: this.getRange(0, 0, 'month')
      }, {
        label: i18n.t('lastmonth'),
        range: this.getRange(1, 1, 'month')
      }]
    },
    getRange(startOffset = 0, endOffset = 0, period = 'day') {
      return [moment().subtract(startOffset, period).startOf(period), moment().subtract(endOffset, period).endOf(period)];
    },
    changeSubset(value) {
      this.$store.dispatch('setShop', value)
    },
    changeDate(value) {
      this.$store.dispatch('setDate', value)
    },
    handleChange(values) {
      this.values = values
      const date = {
        start: moment(values[0])
          .locale('en')
          .format('LLLL'),
        end: moment(values[1])
          .locale('en')
          .format('LLLL'),
      }
      this.dateText = this.setDate
      this.$store.dispatch('setDate', date)
    },
    getCurrentLang() {
      return i18n.locale
    },
  },
  watch: {
    dateSelect: (oldVal, newVal) => {
      true
      this.$store.dispatch('setDate', oldVal)
    },
    'i18n.locale': {
      handler(newVal, oldVal) {
        // Call the method when i18n.locale changes
        this.getCurrentLang();

      },
      deep: true
    }
  },
}
</script>
<style scoped>
.opens-arrow-pos-left {
  top: 35px;
  left: 0px;
  right: auto;
}

.mdrp__panel[data-v-7863e830] {
  font-size: 15px;
  line-height: 1em;
  position: absolute;
  z-index: 3001;
  top: 100px;
  left: 20px;
  width: 250px;
  max-width: none;
  margin-top: 5px;
  margin-left: -9px;
  padding: 0;
  color: inherit;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fff;
}

.c-header-responsive {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

/* ปรับขนาดและตำแหน่งของแต่ละองค์ประกอบ */
.header-item {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
}

.dropdown {
  margin: 0;
}

@media (max-width: 370px) {

  /* ปรับขนาด Vue Date Picker */
  .mdrp__panel[data-v-7863e830] {
    font-size: 12px;
    /* ลดขนาดตัวอักษร */
    width: 200px;
    /* ลดความกว้างของ date picker */
    top: 80px;
    /* ปรับตำแหน่งด้านบน */
    left: 5px;
    /* ปรับตำแหน่งด้านซ้าย */
    margin-left: 0;
    padding: 5px;
    /* ลด padding */
    border: 1px solid #ccc;
    /* ลดขนาดของขอบ */
    border-radius: 3px;
    /* ลดความโค้งมนของขอบ */
  }

  .mdrp__panel .mdrp__calendar {
    border: 1px solid #ddd;
    /* ลดขนาดขอบของปฏิทิน */
    border-radius: 2px;
    /* ลดความโค้งมนของขอบปฏิทิน */
  }

  /* ปรับลูกศรของ Vue Date Picker */
  .opens-arrow-pos-left {
    top: 25px;
  }

  /* ปรับ input group ของ Vue Date Picker */
  .input-date .form-control {
    font-size: 12px;
    /* ลดขนาดตัวอักษร */
    padding: 4px;
    /* ลด padding */
  }

  .input-date .input-group {
    min-width: 180px;
    /* ลดขนาดของ input group */
  }

  /* ปรับตำแหน่งของ HelpButton */
  .c-header-nav-items {
    display: flex;
    flex-wrap: wrap;
    /* ทำให้ไอเทมใน Header ห่อเมื่อจอแคบ */
    justify-content: space-between;
  }

}
</style>
