<template>
  <CFooter style=" background-color: #f6f6f6; ">
    <div>
      <span class="ml-1">&copy; 2018 </span>
      <a href="https://www.silompos.com/" target="_blank">Silom Technology Co., Ltd.</a>
      <span> - ALL RIGHTS RESERVED </span>
    </div>
    <div class="ml-auto">
      <span class="mr-1">version 1.0.1</span>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'TheFooter',
}
</script>
