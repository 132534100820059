<template>
  <div>
    <!-- Dropdown -->
    <CDropdown
      inNav
      class="c-header-nav-items"
      placement="bottom-end"
      add-menu-classes="pt-0"
      style="width: 100%; margin-right:5%"
      :show.sync="showDropdown"
      id="theHeaderDropdownAcc"
    >
      <template #toggler>
        <CHeaderNavLink id="theHeaderDropdownAccToggler" class="p-0">
          <div id="theHeaderDropdownAccAvatar" class="c-avatar" style="width: 30px;height: 30px;"
          >
          <img
            id="theHeaderDropdownAccAvatarImg"
            src="/img/user.png"
            class="c-avatar-img "
          />
          </div>
        </CHeaderNavLink>
      </template>

      <!-- Dropdown Items -->
      <div id="theHeaderDropdownAccMenu" style="padding: 10px 20px;">
        <CDropdownItem id="theHeaderDropdownAccUser" class="dropdown-item-custom" :to="{ path: '/user' }">
          <i class="fi fi-rr-user" style="font-size: 20px; margin-right: 10px"></i>
          <span style="margin-left: 20px">{{ $t('user') }}</span>
        </CDropdownItem>
        <CDropdownItem id="theHeaderDropdownAccShop" class="dropdown-item-custom" :to="{ path: '/setting/shop' }">
          <i class="fi fi-rr-settings" style="font-size: 20px; margin-right: 10px"></i>
          <span style="margin-left: 20px">{{ $t('shop') }}</span>
        </CDropdownItem>
        <CDropdownItem id="theHeaderDropdownAccPermission" class="dropdown-item-custom" :to="{ path: '/setting/permission' }">
          <i class="fi fi-rr-user-unlock" style="font-size: 20px; margin-right: 10px"></i>
          <span style="margin-left: 20px">{{ $t('permissionManage') }}</span>
        </CDropdownItem>
        <!-- <CDropdownItem class="dropdown-item-custom" v-if="$i18n.locale === 'en'" @click="changeLocale('th')">
          <div class="c-avatar" style="width: 24px; height: 24px; margin-right: 5px">
            <img alt="Thai" src="/img/languages/en_icon.png" class="c-avatar-img" />
          </div>
          <span style="margin-left: 20px">English</span>
        </CDropdownItem>
        <CDropdownItem class="dropdown-item-custom" v-if="$i18n.locale === 'th'" @click="changeLocale('en')">
          <div class="c-avatar" style="width: 24px; height: 24px; margin-right: 5px">
            <img alt="English" src="/img/languages/th_icon.png" class="c-avatar-img" />
          </div>
          <span style="margin-left: 20px">ภาษาไทย</span>
        </CDropdownItem> -->

        <CDropdownItem id="theHeaderDropdownAccSignOut" class="dropdown-item-custom" @click="dangerModal = true">
          <i class="fi fi-rr-exit" style="font-size: 20px; margin-right: 10px"></i>
          <span style="margin-left: 20px">{{ $t('signOut') }}</span>
        </CDropdownItem>
      </div>
    </CDropdown>

    <!-- Logout Modal -->
    <CModal
      id="theHeaderDropdownAccLogoutModal"
      color="danger"
      :show.sync="dangerModal"
      centered
      :footer="footer"
      :title="$t('signOut')"
    >
      <h4 id="theHeaderDropdownAccLogoutTitle" class="text-center">{{ $t("dialogSignOut") }}</h4>
      <br />
      <h5 id="theHeaderDropdownAccLogoutConfirm" class="text-center">{{ $t("confirmSignOut") }}</h5>
      <template #footer>
        <CRow class="justify-content-center col-md-12">
          <CCol col="5" lg="4">
            <CButton id="theHeaderDropdownAccLogoutConfirmButton" block v-if="loadingButton === true" color="danger" @click="logout()">
              {{ $t("submit") }}
            </CButton>
            <CButton id="theHeaderDropdownAccLogoutDisabledButton" block color="danger" disabled v-else-if="loadingButton === false">
              <CSpinner color="white" size="sm" /> {{ $t("submit") }}
            </CButton>
          </CCol>
          <CCol col="1" lg="2"></CCol>
          <CCol col="5" lg="4">
            <CButton block color="light" id="theHeaderDropdownAccLogoutCancelButton" @click="dangerModal = false">
              {{ $t("cancel") }}
            </CButton>
          </CCol>
        </CRow>
      </template>
    </CModal>
  </div>
</template>

<script>
import firebase from "firebase/app";
import { mapGetters } from "vuex";
import pos from "@/services/local";

export default {
  data() {
    return {
      showDropdown: false,
      dangerModal: false,
      footer: "",
      loadingButton: true,
    };
  },
  computed: {
    ...mapGetters(["shops", "users"]),
  },
  methods: {
    changeLocale(locale) {
      this.$i18n.locale = locale;
    },
    logout() {
      this.loadingButton = false;
      firebase
        .auth()
        .signOut()
        .then(() => {
          localStorage.clear();
          window.location.href = "/login";
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
/* .dropdown-menu.show{
  margin-top: 10px;
}
.dropdown-item-custom {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #333;
  transition: background-color 0.3s ease;
}

.dropdown-item-custom:hover {
  background-color: var(--success);
  border-radius: 5px;
  
}

.c-header-nav-items .c-avatar {
  cursor: pointer;
}

.c-header-nav-items .c-avatar:hover {
  background-color: #e8e8e8;
} */
</style>
